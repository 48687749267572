<template>
  <div>
    <no-access-info-card
      v-if="showNoAccessInfoCard"
    />
    <div v-else>
      <div v-if="course && course.length">
        <webinar-card
          v-for="(item, index) in course"
          :id="item.id"
          :key="item.id"
          :course="item.attributes"
          :is_past_lable_active="
            Boolean(
              (actualCoursesCount && actualCoursesCount === index) ||
                (!actualCoursesCount && index === 0)
            )
          "
          :can-show-image="showImages"
          @onImgLoad="loadedImages += 1"
          @haveImage="imagesCount += 1"
        />
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-if="pagination.total > pagination.per_page"
            v-model="currentPage"
            :per-page="pagination.per_page"
            :total-rows="pagination.total"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
      <div
        v-if="!course || !course.length"
        class="d-flex align-items-center justify-content-center"
      >
        <img
          src="@/assets/images/pages/course/empty-list.svg"
          alt="Buypass"
          class="d-inline-block mt-5"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { BPagination } from 'bootstrap-vue'
import WebinarCard from '@/components/course/WebinarCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BPagination,
    WebinarCard,
    NoAccessInfoCard,
  },
  data() {
    return {
      loadedImages: 0,
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    showImages() {
      return (
        this.loadedImages >= this.course.filter(el => el.attributes.image).length
      )
    },
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    actualCoursesCount() {
      return this.$store.getters['course/actualCoursesCount']
    },
    currentPage: {
      get() {
        return this.$store.getters['course/pageNumber']
      },
      set(pageNumber) {
        this.loadedImages = 0
        this.$store.commit('course/setPageNumber', pageNumber)
      },
    },
    course() {
      return this.$store.getters['course/course']
    },
    pagination() {
      return this.$store.getters['course/pagination']
    },
    archivedCourse() {
      return this.$store.getters['course/archivedCourse']
    },
  },
  user() {
    return this.$store.getters['auth/user']
  },
  representACompanyAdmin() {
    return 'id' in this.user.active_business_profile && this.user.active_business_profile.role === 'admin'
  },
  showNoAccessInfoCard() {
    if (!this.isAuthenticated) return false
    if (this.representACompanyAdmin) return false
    if (!this.checkPermission('COURSE_READ', this.userPermissions)) return true
    return false
  },
}
</script>
