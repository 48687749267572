<template>
  <div>
    <div
      v-if="is_past_lable_active"
      class="
        past-divider
        d-flex
        align-items-center
        justify-content-between
        mb-2
      "
    >
      <div />
      <h5 class="mb-0">
        {{ $t("Past webinars") }}
      </h5>
      <div />
    </div>
    <b-card
      style="max-width: 100%"
      class="text-center slide-right"
      @click="goToDetails"
    >
      <div
        class="
          d-flex
          flex-md-row flex-column
          align-items-center align-items-md-stretch
        "
      >
        <div
          class="col-md-4 col-xl-3 px-0 pr-md-2 d-flex card-image-container justify-content-center align-items-center"
        >
          <div v-if="course.image">
            <img
              v-show="canShowImage"
              :src="course.image"
              alt="Image"
              class="rounded job-card-image"
              @load="onImgLoad"
            >
            <img
              v-show="!canShowImage"
              class="rounded job-card-image"
              src="@/assets/images/cards/webinar_card_image.png"
              alt=""
            >
          </div>
          <img
            v-else
            class="rounded job-card-image"
            src="@/assets/images/cards/webinar_card_image.png"
            alt=""
          >
        </div>
        <div class="col-md-6 col-xl-7 d-flex flex-column pl-0">
          <div
            class="
              flex-column flex-lg-row
              d-flex
              justify-content-between
              align-items-start
              m-0
              p-0
              w-100
            "
          >
            <h4 class="font-weight-bolder text-dark text-md-left w-100 mt-1 mt-md-0">
              {{ course.title }}
            </h4>
          </div>
          <div
            class="
              flex-column flex-lg-row
              d-flex
              justify-content-between
              align-items-start
              m-0
              p-0
              w-100
            "
          >
            <h5
              class="text-left w-100 ellipsis-2-line mb-1 description-no-style"
              v-html="course.description"
            />
          </div>

          <div class="d-flex flex-wrap m-0 p-0 align-items-center mt-auto">
            <img
              v-if="course.owner.image"
              :src="course.owner.image"
              alt=""
              class="workplace-image mb-0 mt-10 mr-1 h5"
            >
            <h5
              v-for="tag in translate(course.specialities, $i18n.locale)"
              :key="tag.id"
              class="mr-1 mb-0 mt-10"
            >
              <b-badge
                class="text-dark font-weight-normal"
                variant="secondary"
              >
                {{ tag.name }}
              </b-badge>
            </h5>
          </div>
        </div>
        <div
          class="
            col-md-2
            ml-auto
            px-0
            d-flex
            align-items-md-end
            justify-content-between
            align-items-center
            flex-md-column
          "
        >
          <h5 class="text-primary font-weight-bold mb-0 mb-md-1 mt-1 mt-md-0">
            {{
              {
                date: course.start_date,
                locale: $i18n.locale,
              } | transformDate
            }}
          </h5>
          <div class="mt-md-auto mt-1">
            <div v-if="is_own && !isViewer">
              <!-- <feather-icon
              :id="`popover-button-${id}`"
              size="1.2x"
              class="text-primary mr-1 cursor-pointer"
              icon="InfoIcon"
              @click.stop
            />
            <b-popover
              :target="`popover-button-${id}`"
              triggers="hover"
              placement="top"
              style="border:none"
              custom-class="text-center"
            >
              {{ $t('Course is submitted for administration review. Once it is approved you will no longer be able to edit it.') }}
            </b-popover> -->
              <b-button
                variant="primary"
                @click.stop="$router.push(`/my_courses/${id}`)"
              >
                {{ $t("Draft") }}
              </b-button>
            </div>
            <div
              v-if="!is_own && !isPast(course.start_date, course.start_time, course.estimated_duration) && !isViewer"
              class="p-0 text-right mb-1 mb-md-0"
            >
              <b-button
                variant="primary"
                @click.stop="attendWebinar"
              >
                {{ $t(course.is_subscribed ? "Leave webinar" : "Attend webinar") }}
              </b-button>
            </div>
            <h5 class="text-muted text-right mt-md-1 mt-0 mb-0">
              {{ `${course.amount_of_subscribers} ${$t("Attendees")}` }}
            </h5>
          </div>
          <!-- <div
                v-if="
                  isLive(
                    course.start_date,
                    course.start_time,
                    course.estimated_duration
                  )
                "
                class="
              text-primary
              d-flex
              align-items-center
              justify-content-lg-end justify-content-center
            "
              >
                <span class="live-indicator" />
                {{ $t('Now is live') }}
              </div>
              <h6
                v-else
                class="text-primary text-lg-right"
              >
                {{ course.start_time }}
                -
                {{
                  {
                    startTime: course.start_time,
                    duration: course.estimated_duration,
                  } | endTime
                }}
              </h6> -->
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import moment from 'moment/min/moment-with-locales'

import {
  BCard, BBadge, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
  },
  filters: {
    transformDate(obj) {
      moment.locale(obj.locale)
      if (obj.locale === 'en') {
        return moment(obj.date).format('DD.MM.YYYY')
      }
      moment.locale('nb')
      return moment(obj.date).format('DD.MM.YYYY')
    },
    endTime(time) {
      return moment(time.startTime, 'H:mm')
        .add(+time.duration, 'minutes')
        .format('H:mm')
    },
  },
  props: {
    // eslint-disable-next-line
    course: {
      type: Object,
    },
    // eslint-disable-next-line
    id: {
      type: String,
    },
    is_own: {
      type: Boolean,
      default: false,
    },
    is_past_lable_active: {
      type: Boolean,
      default: false,
    },
    canShowImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeTab() {
      return this.$store.getters['course/activeTab']
    },
    user() {
      return this.$store.getters['auth/user']
    },
    isViewer() {
      return this.user.active_business_profile.role === 'viewer'
    },
    representsACompany() {
      return this.user.active_business_profile.id
    },
  },
  methods: {
    onImgLoad() {
      this.$emit('onImgLoad')
    },
    goToDetails() {
      if (this.representsACompany) return
      this.$router.push(
        `/course/webinares/${this.id}/${this.is_own ? 'private' : 'regular'}`,
      )
    },
    isLive(date, time, duration) {
      const startDateTime = moment(`${date} ${time}`).valueOf()
      const currentDateTime = moment(Date.now()).valueOf()
      const endTime = moment(time, 'H:mm')
        .add(+duration, 'minutes')
        .format('H:mm')
      const endDateTime = moment(`${date} ${endTime}`).valueOf()
      return startDateTime < currentDateTime && endDateTime > currentDateTime
    },
    isPast(date, time, duration) {
      const currentDateTime = moment(Date.now()).valueOf()
      const endTime = moment(time, 'H:mm')
        .add(+duration, 'minutes')
        .format('H:mm')
      const endDateTime = moment(`${date} ${endTime}`).valueOf()
      return currentDateTime > endDateTime
    },
    attendWebinar() {
      this.$store
        .dispatch(
          this.course.is_subscribed
            ? 'course/leaveCourse'
            : 'course/subscribeCourse',
          this.id,
        )
        .then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(
                  this.course.is_subscribed
                    ? 'You have successfully leaved the course'
                    : 'You have successfully subscribed for the course',
                ),
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            if (this.activeTab) {
              this.$store.dispatch('course/fetchMyCourses')
            } else {
              this.$store.dispatch('course/fetchCourses')
            }
          }
        })
    },
  },
}
</script>
